.d {
  @apply border-2 border-[red] border-solid;
}

.text-anchor {
  @apply text-base text-dark;
}


@font-face {
  font-family: "regular";
  src: url("assets/fonts/regular.otf");
}

@font-face {
  font-family: "bold";
  src: url("assets/fonts/bold.otf");
}
@font-face {
  font-family: "light";
  src: url("assets/fonts/light.otf");
}
@font-face {
  font-family: "medium";
  src: url("assets/fonts/medium.woff2");
}