.imgslide {
  position: absolute;
  left: 15px;
  right: 15px;
}

.imgslide-enter {
  opacity: 0;
}

.imgslide-enter-active {
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  transition-duration: 500ms;
  -webkit-transition-property: opacity; /* WebKit/Blink browsers */
  -webkit-transition-duration: 500ms; /* WebKit/Blink browsers */
  -moz-transition-property: opacity; /* Mozilla Firefox */
  -moz-transition-duration: 500ms; /* Mozilla Firefox */
  -o-transition-property: opacity; /* Opera */
  -o-transition-duration: 500ms; /* Opera */
}

.imgslide-exit {
  opacity: 1;
}

.imgslide-exit-active {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 500ms;
  -webkit-transition-property: opacity; /* WebKit/Blink browsers */
  -webkit-transition-duration: 500ms; /* WebKit/Blink browsers */
  -moz-transition-property: opacity; /* Mozilla Firefox */
  -moz-transition-duration: 500ms; /* Mozilla Firefox */
  -o-transition-property: opacity; /* Opera */
  -o-transition-duration: 500ms; /* Opera */
}

.bg-banner-overlay {
  background: linear-gradient(
      180deg,
      rgba(201, 205, 207, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    #fff;
}
.stats-bg {
  background: url('../../assets/home/dna.png'),
    linear-gradient(to bottom, #f0f4f5, #f0f4f5);
  background-size: 100% auto, cover;
  background-position: right top, left top;
  background-repeat: no-repeat, no-repeat;
}

@media screen and (max-width: 768px) {
  .stats-bg {
    background-size: 120% auto, cover;
    background-position: left 10px top 10px, left top;
  }
}

.mouse-carousel-wrapper:hover {
  cursor: url('data:image/svg+xml,<svg width="48" height="48" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="50.5249" cy="49.9438" r="49.2779" fill="white" stroke="%23D9D9D9" stroke-width="1.33184"/><path d="M35.525 37.9402L22.1348 49.9436L35.525 61.9474" stroke="black" stroke-width="2.7"/><path d="M65.5248 37.9402L78.915 49.9436L65.5248 61.9474" stroke="black" stroke-width="2.7"/></svg>'),
    auto;
}

.marquee {
  flex-shrink: 0;
  min-width: 100%;
  animation-name: scroll-x;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-fill-mode: alternate;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll-x;
  -webkit-animation-duration: 20s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: alternate;
  -webkit-animation-fill-mode: alternate;
  -webkit-animation-iteration-count: infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 2rem));
  }
}

.test-box {
  background-image: repeating-linear-gradient(
      120deg,
      #333333,
      #333333 7px,
      transparent 7px,
      transparent 12px,
      #333333 12px
    ),
    repeating-linear-gradient(
      210deg,
      #333333,
      #333333 7px,
      transparent 7px,
      transparent 12px,
      #333333 12px
    ),
    repeating-linear-gradient(
      -60deg,
      #333333,
      #333333 7px,
      transparent 7px,
      transparent 12px,
      #333333 12px
    ),
    repeating-linear-gradient(
      30deg,
      #333333,
      #333333 7px,
      transparent 7px,
      transparent 12px,
      #333333 12px
    );
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

@keyframes borderAnimation {
  from {
    background-position: 0 0, -60.01px 0, 100% -60.01px, 0 100%;
  }
  to {
    background-position: 0 -60.01px, 0 0, 100% 0, -60.01px 100%;
  }
}

.products-gradient::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: auto;
  height: 85%;
  border-radius: 50%;
  filter: blur(100px);
  bottom: -10%;
  left: 10%;
  right: 10%;
  background: white;
}

.product-feature-card {
  border-radius: 13px;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

@media screen and (max-width: 976px) {
  .product-feature-card {
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
  }
}

.map-wrapper .swiper-pagination-bullet-active {
  background-color: #f69a4d !important;
}
